
.lower-footer-wrapper {
  background-color: var(--secondary-theme-color);
  display: flex;
  flex-direction: column;
  min-height: $footer-bottom-height;
  padding: $footer-padding;
  padding-bottom: 50px;
  padding-top: 100px;
  @media screen and (max-width: $res-tablet) {
    padding-bottom: 150px;
    padding-left: $side-padding-sm;
    padding-right: $side-padding-sm;
    padding-top: 60px;
    .logo-wrapper {
      margin-bottom: 20px;
    }
  }
  .upper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 1;
    .subscribe-form {
      display: flex;
      .basic-input {
        margin-right: 10px;
        min-width: 300px;
      }
       
    }
    .logo-wrapper {
      margin-top: 20px;
    }
    .subscribe-wrapper {
      margin-top: 20px;
      position: relative;
      > p {
        margin-bottom: 20px;
      }
      .message {
        bottom: -40px;
        position: absolute;
      }
      .subscribe-form {
        flex-wrap: wrap;
        button {
          margin-bottom: 20px;
        }
        @media screen and (max-width: $res-tablet) {
          .basic-input {
            margin: 0;
            min-width: unset;
          }
            
        }
      }
    }
  }
  .lower {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 50px;
    p {
      font-size: 14px;
    }
    .right-side {
      display: flex;
      p:first-child {
        margin-right: 10px;
      }
    }
    @media screen and (max-width: $res-tablet) {
      flex-direction: column-reverse;
      .right-side,
      .left-side {
        justify-content: space-evenly;
        text-align: center;
      }
      .right-side {
        margin-bottom: 20px;
      }
    }
  }
}
