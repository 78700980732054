.fifth-banner {
  .content-items {
    .row {
      max-width: 700px;
      .italic {
        font-style: italic;
      }
    }
    .upper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      z-index: 1;
    }
  }
}
