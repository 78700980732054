@import '../../../common//styles//variables.scss';
.white-paper-container {
  .usual-banner {
    .content-items {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;
      padding-top: 100px;
      .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .row {
          flex: 1;
        }
      }
      .image {
        position: relative;
      }
    }
    .submit-message {
      &.hidden {
        color: var(--secondary-font-color);
      }
    }
  }
  @import './first-banner';
}
