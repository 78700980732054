
@import '../../../common/styles/variables';
.contact-us-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .basic-input:not(.full-width) {
    max-width: 49%;
  }
  @media screen and (max-width: $res-tablet) {
    .basic-input:not(.full-width) {
      max-width: 100%;
    }
  }
  .full-width {
    width: 100%;
  }
  textarea {
    min-height: 100px;
  }
}
