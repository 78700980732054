@import '../../../../common/styles/variables';
.header-container {
  background-color: var(--primary-theme-color);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  &.scrolled-down {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
  
  }
  &.scrolled-up {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
  }
  .header-wrapper {
    align-items: center;
    display: flex;
    height: $header-height;
    justify-content: space-between;
    padding: $header-padding-lg;
    &.sticky {
      position: sticky;
      top: 0;
      z-index: 100;
    }
    .logo-container {
      padding-right: 20px;
    }
    @media screen and (max-width: $res-laptop-l) {
      padding: $header-padding-md;
    }
    @media screen and (max-width: $res-tablet) {
      padding: $header-padding-sm;
    }
  }
  .burger-menu,
  .copyright {
    display: none;
  }
  @media screen and (max-width: $res-laptop) {
    .menu-container {
      display: none;
    }
    .burger-menu {
      display: block;
    }
    .burger-menu.open + div {
      background-color: var(--primary-theme-color);
      bottom: 0;
      display: flex;
      flex-direction: column;
      height: calc(100vh - #{$header-height});
      left: 0;
      padding-bottom: 20px;
      padding-left: $side-padding-md;
      position: fixed;
      right: 0;
      top: $header-height;
      width: 100vw;
      .item {
        margin-top: 20px;
        label {
          font-size: 24px;
        }
      }
      .copyright {
        display: block;
        margin-top: auto;
        p {
          font-size: 14px;
        }
      }
    }
  }
  @media screen and (max-width: $res-laptop) {
    .burger-menu.open + div {
      padding-left: $side-padding-sm;
    }
  }
}
