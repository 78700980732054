.pointer {
  cursor: pointer;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}
.f-primary {
  color: var(--primary-font-color);
}
.f-secondary {
  color: var(--secondary-font-color);
}
.f-tertiary {
  color: var(--tetiary-font-color);
}
.f-green {
  color: var(--banner-light-green);
}
.bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
a {
  color: var(--primary-font-color);
}
