.text-area-input {
  display: inline-block;
  min-width: 200px;
  textarea {
    background-color: var(--input-background-color);
    border: 1px solid transparent;
    color: var(--input-text-color);
    font-family: DM Sans;
    font-size: 16px;
    outline: none;
    padding: 10px 20px;
    resize: none;
    width: 100%;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--placeholder-color);
      opacity: 1; 
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--placeholder-color);
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--placeholder-color);
    }
  
  }
  .error {
    textarea {
      border: 1px solid var(--error-message-color);;
    }
  }
  .error-message {
    font-size: 12px;
    height: 20px;
    visibility: hidden;
    &.visible {
      color: var(--error-message-color);
      visibility: visible;
    
    }
  }
}
