.menu-container {
  display: flex;
  flex-direction: row;
  .item {
    padding: 0 20px;
    position: relative;
    &:last-child {
      padding-right: 0;
    }
    .placeholder {
      visibility: hidden;
    }
    .main {
      left: 0;
      position: absolute;
      top: 0;
      top: 50%;
      transform: translateY(-50%);
      &.active {
        color: var(--banner-light-green);
        font-weight: 900;
      }
    }
  }
}
