@import '../../../../common/styles/variables.scss';
$line-spacing: 8px;
$line-thickness: 3px;
$line-width: 35px;
.burger-menu {
  .nav-icon {
    cursor: pointer;
    height: calc(3 * #{$line-spacing});
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    width: $line-width;
  }
  .nav-icon span {
    background: black;
    // border-radius: 9px;
    display: block;
    height: $line-thickness;
    left: 0;
    opacity: 1;
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: $navigation-animation ease-in-out;
    -moz-transition: $navigation-animation ease-in-out;
    -o-transition: $navigation-animation ease-in-out;
    transition: $navigation-animation ease-in-out;
    width: 100%;
  }
  .nav-icon span:nth-child(1) {
    top: calc(#{$line-spacing * 0});
  }
  .nav-icon span:nth-child(2) {
    top: calc(#{$line-spacing * 1});
  }
  .nav-icon span:nth-child(3) {
    top: calc(#{$line-spacing * 2});
  }
  &.open {
    .right {
      &.nav-icon span:nth-child(1) {
        opacity: 0;
        top: $line-spacing;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &.nav-icon.open span:nth-child(2) {
        left: -60px;
        opacity: 0;
      }
      &.nav-icon.open span:nth-child(3) {
        opacity: 0;
        top: $line-spacing;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
    .left {
      &.nav-icon span:nth-child(1) {
        opacity: 0;
        top: $line-spacing;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
      &.nav-icon span:nth-child(2) {
        left: 160px;
        opacity: 0;
      }
      &.nav-icon span:nth-child(3) {
        opacity: 0;
        top: $line-spacing;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
    }
    .center {
      &.nav-icon span:nth-child(1) {
        top: $line-spacing;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &.nav-icon span:nth-child(2) {
        left: -60px;
        opacity: 0;
      }
      &.nav-icon span:nth-child(3) {
        top: $line-spacing;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
 
}
