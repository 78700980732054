.upper-footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: $footer-height;
  padding: $footer-padding;
  padding-bottom: 100px;
  padding-top: 50px;
  @media screen and (max-width: $res-laptop) {
    padding-left: $side-padding-md;
    padding-right: $side-padding-md;
  }
  @media screen and (max-width: $res-tablet) {
    padding-left: $side-padding-sm;
    padding-right: $side-padding-sm;
  }
  .middle,
  .right {
    .title {
      letter-spacing: 4px;
    }
  }
  .left { 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 40px 0 0;
    width: 50%;
    .text {
      margin-top: 53px;
      max-width: 300px;
    }
    @media screen and (min-width: 1560px) {
      width: 33%;
    }
  }
  .middle {
      
    max-width: 210px;
    padding: 0 40px 0 0;
    &__items {
      display: flex;
      flex-direction: column;
      >div {
        max-width: 200px;
      }
    }
  }
  
  @media screen and (min-width: 1560px) {
    .middle {
      max-width: 500px;
      &__items {
        flex-direction: row;
        flex-wrap: wrap;
      }
    } 
  }
  .right {
    padding: 0 40px 0 0;
    width: 25%;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }
  .social-media {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    .social-media-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px 20px 5px 5px;
      .image {
        background-repeat: no-repeat;
        background-size: contain;
        height: 20px;
        margin-bottom: 10px;
        width: 20px;
      }
    }
  
  }
  .title {
    margin-bottom: 25px;
  }
  label {
    display: block;
    margin-bottom: 10px;
  }
  @media screen and (max-width: $res-laptop) {
    .social-media {
      margin: 40px 0;
     
    }
    .middle,
    .right {
      .title {
        margin-bottom: 20px;
      }
    }
    .right {
      .title {
        margin-top: 30px;
      }
    }
  }
}
