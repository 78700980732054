.seventh-banner {
  margin-bottom: 60px;
  margin-top: 60px;
  .main-title {
    margin-bottom: 60px;
    padding: 0 $side-padding-sm;
    text-align: center;
  }
  .content-items {
    padding-left: 0;
    padding-right: 0;
  }
  .row {
    width: 100%;
    .stepper-wrapper {
      align-items: center;
      background-color: var(--stepper-sub-bg);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 20px;
    }
    .text-below {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 20px 20px;
      text-align: center;
      width: 100%;
    }
    .number {
      display: none;
    }
    .buttons {
      button {
        margin: auto;
      }
    }
    @media screen and (max-width: $res-tablet) {
      .text-below {
        text-align: left;
        p {
          max-width: 300px;
        }
      }
      .buttons {
        button {
          margin: 0;
          margin-right: auto;
        }
      }
      .stepper-box {
        position: relative;
        .number {
          bottom: -11px;
          display: block;
          font-size: 80px;
          opacity: 0.4;
          position: absolute;
        }
      }
      .stepper-column {
        z-index: 1 !important;
      }
      .stepper-column:first-child,
      .stepper-column:last-child {
        display: none;
      }
    }
  }
}
