
@import '../../../common//styles//variables.scss';
.white-paper-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .basic-input:not(.full-width) {
    max-width: 49%;
  }
  @media screen and (max-width: $res-tablet) {
    .basic-input:not(.full-width) {
      max-width: 100%;
    }
  }
  .full-width {
    width: 100%;
  }
  .btn {
    margin: 10px 0;
  }
  textarea {
    min-height: 100px;
  }
  .form-label-sub {
    margin-bottom: 10px;
  }
  .submit-message {
    visibility: hidden;
  }
}
