@import '../../../../common/styles/variables';
.blog-post-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px;
  .blog-post {
    background-color: var(--primary-theme-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 450px;
    min-height: 550px;
    padding: 20px;
    .image {
      background-repeat: no-repeat;
      background-size: cover;
      height: 220px;
      width: 100%;
    }
  }
}
