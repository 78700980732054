
.clarity-container {
  .usual-banner {
    .content-items {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;
      padding-top: 100px;
      .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .row {
          flex: 1;
        }
      }
    }
  }

  @import './first-banner';
  @import './second-banner';
  @import './third-banner';
  @import './fourth-banner';
  @import './fifth-banner';
}
