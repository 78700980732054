@import '../../../../common/styles/variables.scss';
.stepper {
  display: flex;
  width: 100%;
  .stepper-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: center;
    position: relative;
    text-align: center;
  }
  
  @media screen and (max-width: $res-tablet) {
    flex-direction: column;
    .stepper-column {
      width: 100% !important;
    }
    .stepper-box {
      align-items: end;
      justify-content: flex-start;
      min-height: 200px;
      padding-left: 20px;
      padding-top: 50px;
      text-align: left;    }
  }
}
