.sixth-banner {
  background: linear-gradient(to right, var(--tertiary-theme-color) 0%, var(--tertiary-theme-color) 100%, var(--primary-theme-color) 100%, var(--primary-theme-color) 100%);
  margin-top: $header-height;
  .content-items {
    padding-left: 0;
    .items {
      flex-direction: unset;
    }
    .row {
      &.num-0 {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        .single-img {
          max-width: 100%;
          img {
            width: 100%;
          }
        }
      }
      .image-group {
        max-width: 61%;
        position: relative;
        .main-image {
          display: flex;
          flex-direction: column;
          max-width: 100%;
        }
        .second-image {
          position: absolute;
          right: -76%;
          top: 10%;
        }
       
        @media screen and (max-width: $res-tablet) {
          .text-group {  
          
            margin-top: 45%;
            width: 100vw;       
          }
          .second-image {
            right: -68%;
            top: 13%;
          }
        }


      }
      .text-group {
        background-color: var(--banner-2-light-green);
        padding-bottom: 40px;
      }
     
      
    }
  }
}
