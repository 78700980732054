@import '../../../common//styles//variables.scss';
.case-study-container {
  .usual-banner {
    .content-items {
      display: flex;
      flex-direction: column;
      .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }
      .image {
        position: relative;
      }
      .text-margin {
        margin-top: 20px;
      }
      .text-margin-s {
        margin-top: 10px;
      }
      .heading-margin {
        margin-top: 40px;
      }
      .with-line {
        position: relative;
        &::before {
          background-color: var(--banner-light-green);
          bottom: -20px;
          content: '';
          left: -30px;
          position: absolute;
          top: 0;
          width: 1px;
        }
        @media screen and (max-width: $res-tablet) {
          &::before {
            left: calc(-#{$side-padding-sm} / 2);
          }
        }
      }
    }
    &.group-banner {
      > .content-items {
        padding: 0 !important;
        .num-1 {
          width: 100%;
        }
        @media screen and (max-width: $res-tablet) {
          > .items {
            flex-direction: column-reverse;
          }
        }
      }
    }
    em {
      color: var(--primary-font-color);
      font-style: inherit;
      font-weight: bold;
    }
    li {
      margin-top: 10px;
    }
    .social-media {
      p {
        padding-right: 10px;
        text-align: right;
      }
      .social-links {
        align-items: center;
        display: flex;
        justify-content: center;
        .single-item {
          display: inline;
          padding: 10px;
        }
      }
    }  
    .social-media.upper {
      display: none;
    }
    @media screen and (max-width: $res-tablet) {
      .social-media.lower {
        display: none;
      }
      .social-media.upper {
        display: flex;
        margin: 10px 0;
        .single-item:first-child {
          padding-left: 0;
        }
      }
    }
  
  }
  @import './first-banner';
  @import './second-banner';
  @import './third-banner';
  @import './fourth-banner';
  @import './fifth-banner';
  @import './sixth-banner';
  @import './seventh-banner';
}
