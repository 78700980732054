@import './theme-variables/css-variables';

$primary-font-family: DM Sans;
$side-padding-sm: 30px;
$side-padding-md: 80px;
$side-padding-lg: 150px;
$header-height: 100px;
$header-height-collapsed: 50px;
$header-padding-sm: 0 $side-padding-sm;
$header-padding-md: 0 $side-padding-md;
$header-padding-lg: 0 $side-padding-lg;

$footer-height: 600px;
$footer-bottom-height: 300px;
$footer-padding: 50px $side-padding-lg;

$banner-light-gray: #cdd2d7;
$banner-medium-gray: #8e939f;
$banner-dark-gray: #1d2535;

$res-tablet: 769px;
$res-laptop: 1024px;
$res-laptop-l: 1440px;
$res-high: 2560px;
$page-max-width: 1800px;

$navigation-animation: 0.4s;
* {
  @each $label, $value, $i in $standard-theme {
    #{$label}: #{$value};
  }
}
