.third-banner {
  .content-items {
    background-color: var(--primary-font-color);
    margin-top: 50px;
    padding-left: 0;
    padding-right: 0;
  }
  .heading-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    text-align: center;
    @media screen and (max-width: $res-tablet) {
      align-items: flex-start;
      padding-left: $side-padding-sm;
    }
    .heading {
      padding-bottom: 40px;
    }
    .line {
      background-color: var(--secondary-font-color);
      height: 3px;
      width: 30px;
    }
  }
  .row {
    width: 100%;
    .icons {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: center;
      .single-item {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 auto;
        min-height: 230px;
        padding: 40px 20px;
        position: relative;
        text-align: center;
        width: 300px;
        p {
          text-align: left;
        }
      }
      .line {
        background-color: #4a5160;
        height: 120px;
        margin-top: 50px;
        top: 29%;
        width: 1px;
      }
      @media screen and (max-width: $res-tablet) {
        .single-item {
          margin-left: 0; 
        }
      }
      .title {
        font-size: 82px;
        text-align: left;
      }
    }
  }
}
