.main-container {
  height: 100vh;
  overflow: auto;
  position: fixed;
  width: 100%;
  &__content {
    >div {
      min-height: calc(100vh - #{$footer-height} - #{$footer-height} - #{$header-height});
    }
  } 
  .content-items,
  .upper-footer-wrapper,
  .lower-footer-wrapper,
  .header-wrapper {
    display: flex;
    margin: auto;
    max-width: $page-max-width;
    position: relative;
    width: 100%;
  }
}


