@import '../../../../common/styles/variables';
.upper-footer {
  background-color: var(--primary-theme-color);
  @import './upper-footer';
}
.lower-footer {
  background-color: var(--secondary-theme-color);
  position: relative;
  @import './lower-footer';
}


