
@import '../../../common//styles//variables.scss';
.three-drm-container {
  .usual-banner {
    .content-items {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;
      padding-top: 100px;
      .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .row {
          flex: 1;
        }
      }
      .image {
        position: relative;
      }
    }
  }
  @import './first-banner';
  @import './second-banner';
  @import './third-banner';
  @import './fourth-banner';
  @import './fifth-banner';
  @import './sixth-banner';
  @import './seventh-banner';
  @import './eight-banner';
}
