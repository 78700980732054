.col-lg-4 {
  flex: unset !important;
  width: 33% !important;
}
.col-lg-6 {
  flex: unset !important;
  width: 50% !important;
}
.visible-sm {
  display: none;
}

@media screen and (max-width: $res-laptop) {
  .col-md-12 {
    flex: unset !important;
    width: 100% !important;
  }
  .row {
    &.num-0 {
      padding-bottom: 20px;
    }
  }
  .col-md-6 {
    flex: unset !important;
    width: 50% !important;
  }
  .responsive-image {
    width: 100%;
  }
  .invisible-md {
    display: none !important;
  }
  .visible-md {
    display: block !important;
  }
}
@media screen and (max-width: $res-tablet) {
  .col-sm-12 {
    flex: unset !important;
    width: 100% !important;
  }
  .row {
    &.num-0 {
      padding-bottom: 20px;
    }
  }
  .responsive-image {
    width: 100%;
  }
  .invisible-sm {
    display: none !important;
  }
  .visible-sm {
    display: block;
  }
}
