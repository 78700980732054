@import '../../styles/variables';
.btn {
  align-items: center;
  background-color: var(--btn-success);
  border: 0;
  border: 1px solid transparent;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  outline: 0;
  padding: 0 10px;
  text-align: center;
  transition: 0.2s all;
  &:hover {
    opacity: 0.7;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  //colors
  &.btn-green {
    background-color: var(--btn-success);
  }
  &.btn-dark {
    background-color: var(--btn-dark);
  }
  &.btn-transparent {
    background-color: transparent;
    border: 2px solid;
    border-color: var(--button-transparent-border-color);
    &.dark {
      border-color: var(--button-transparent-dark-border-color);
    }
  }
  //btn size 
  &.btn-small {
    min-width: 166px;
  }
  &.btn-medium {
    min-width: 290px;
  }
  &.btn-large {
    width: 100%;
  }
}
