@import '../../../common/styles/variables';
.basic-input {
  display: inline-block;
  min-width: 130px;
  &.transparent {
    input {
      background-color: var(--input-transparent-background-color);
      color: var(--secondary-font-color);
    }
  }
  .input-wrapper {  
    width: 100%;
    &.error {
      input {
        border: 1px solid var(--error-message-color);
      }
    }
   
  }
  input {
    background-color: var(--input-background-color);
    border: 1px solid transparent;
    font-family: DM Sans;
    font-size: 16px;
    height: 56px;
    outline: none;
    text-indent: 20px;
    width: 100%;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--placeholder-color);
      opacity: 1; 
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--placeholder-color);
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--placeholder-color);
    }
  }
  .error-message {
    font-size: 12px;
    height: 20px;
    visibility: hidden;
    &.visible {
      color: var(--error-message-color);
      visibility: visible;
    }
  }
}
.basic-input {
  .PhoneInputCountry {
    align-items: center;
    background-color: var(--input-background-color);
    display: flex;
    justify-content: center;
    max-width: 15%;
    width: 10%;
  }
}
.basic-input.PhoneInput {
  display: flex;
  +.error-message {
    color: var(--error-message-color);
    font-family: DM Sans;
    font-size: 16px;
    font-size: 12px;
    height: 20px;
    visibility: visible;
  }
}
