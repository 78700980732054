$standard-theme: ( 
  (--primary-theme-color, #fff, primaryThemeColor),
  (--secondary-theme-color, #2f4858, secondaryThemeColor),
  (--tertiary-theme-color, #f7fbfe, tertiaryThemeColor),
  (--primary-font-color, #1d2535, primaryFontColor),
  (--secondary-font-color, #fff, secondaryFontColor),
  (--tetiary-font-color, #97a3ab, tetiaryFontColor),
  (--btn-success, #58b895, btnSuccess),
  (--btn-dark, #1e2535, buttonDark),
  (--button-transparent-border-color, #fff, buttonTransparentBorderColor),
  (--button-transparent-dark-border-color, #e7e5ea, buttonTransparentDarkBorderColor),
  (--banner-light-green, #58b895, bannerLightGreen),
  (--banner-medium-green, #298b8d, bannerMediumGreen),
  (--banner-dark-green, #2d5d6e, bannerDarkGreen),
  (--placeholder-color, #9f9f9f, placeholderColor),
  (--error-message-color, red, errorMesssageColor),
  (--input-background-color, #e5e7ea, inputBackgroundColor),
  (--input-text-color, #424242, inputTextColor),
  (--checkbox-checked-color, #1d2535, chekboxCheckedColor),
  (--checkbox-not-checked-color, #fff, chekboxNotCheckedColor),
  (--checkbox-checkmark-color, #fff, checkboxCheckmarkColor),
  (--stepper-color-light, #00beb3, stepperColorLight),
  (--stepper-color-medium, #009baf, stepperColorMedium),
  (--stepper-color-semi-dark, #287480, stepperColorSemiDark),
  (--stepper-color-dark, #2d5d6e, stepperColorDark),
  (--banner-2-light-green, #dafff2, banner2LightGreen),
  (--banner-2-dark-green, #cbfbe7, banner2DarkGreen),
  (--main-border-color, #b3b3b3, mainBorderColor), 
  (--map-gray-color, #f5f4f4, mapGrayColor), 
  (--stepper-sub-bg, #f9fafb, stepperSubBg),
  (--input-transparent-background-color, rgba(255, 255, 255, 0.2), inputTransparentBacgroundColor));
:export { @each $label, $value, $propName, $i in $standard-theme {
    #{$propName}: #{$value};
  };}



