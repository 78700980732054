.eight-banner {
  @media screen and (max-width: $res-laptop) {
    .content-items {
      padding-top: 40px;
    }
  }
  .items {
    justify-content: center;
    .row {
      &.num-1,
      &.num-0 {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 600px;
        text-align: center;
      }
      @media screen and (max-width: $res-laptop) {
        &.num-0 {
          margin-bottom: 40px;
        }
      }
      img {
        margin-bottom: 30px;
      }
      h5,
      p {
        margin-bottom: 20px;
        max-width: 60%;
        @media screen and (max-width: $res-tablet) {
          max-width: 100%;
        }
      }
    }
  }
  .lower {
    background-color: var(--main-border-color);
    height: 1px;
    margin: auto;
    width: 100%;
  }
}
