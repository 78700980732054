.second-banner {
  .items {
    margin-top: 40px;
  }
  .row {
    &.num-0 {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      max-width: 1054px;
      .single-item-wrapper {
        margin: 20px auto;
        max-width: 350px;
        min-height: 350px;
        padding: 10px;
        width: 32%;
        width: 100%;
       
      }
      .single-item {
        align-items: flex-start;
        background-color: var(--tertiary-theme-color);
        height: 100%;
        padding: 20px;
        position: relative;
        &.green {
          background-color: var(--banner-2-light-green);
          display: flex;
          flex-direction: column;
          margin-top: 0;
          .text {
            display: flex;
            flex-direction: column;
            height: 100%;
            margin-top: 0;
            h4 {
              max-width: 155px;
            }
          }
          .buttons {
            margin-top: auto;
          }
          @media screen and (max-width: $res-tablet) {
            .buttons {
              margin-top: 40px;
            }
          }
        }       
        img {
          height: 66px;
          position: absolute;
          top: -31px;
        }
        .text {
          margin-top: 30px;
          padding: 0 10px 10px;
          > * {
            max-width: 400px;
          }
        }
        .mt {
          margin-top: 15px;
        }
      }
     
    }
  }
  .heading {
    text-align: center;
    @media screen and (min-width: $res-laptop) {
      margin-bottom: 50px !important;
      margin-top: 100px !important;
    }
  }
}
