
@import '../../../common/styles/variables.scss';
.backup-policy-container {
  padding-top: $header-height;
  .usual-banner {
    .content-items {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;
      padding-top: 100px;
      .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .row {
          flex: 1;
        }
      }
      .image {
        height: 100%;
        position: relative;
        width: 100%;
      }
   
    }
  }
  @import './first-banner';
}
