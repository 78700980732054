@import './variables';
* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
body {
  box-sizing: border-box;
  color: var(--default-font-color);

  // default font settings
  font-family: $primary-font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
pre {
  color: var(--primary-font-color);
  font-family: $primary-font-family;
}
h1 {
  font-size: 48px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 56px;
}
h2 {
  font-size: 42px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
}
h3 {
  font-size: 36px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.8px;
  line-height: 46px;
}
h4 {
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.8px;
  line-height: normal;
}
h5 {
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 32px;
}
h6 {
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;
}
p {
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 28px;
}
label {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.355556px;
  line-height: 26px;
}
pre {
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 28px;
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-wrap: break-word;
}
li {
  font-size: 18px;
}
ul {
  margin-left: 20px;
}
ol {

  margin-left: 15px;
}

@media screen and (max-width: $res-laptop) {
  h1 {
    font-size: 44px;
    line-height: 45px;
  }
  #hubspot-messages-iframe-container {
    display: none !important;
    visibility: hidden;
  }
}

//other global styles
@import './global-classes';
@import './responsive-style';
@import './main';
@import './fonts';




