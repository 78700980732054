@import '../../../common/styles/variables';
.select {
  display: inline-block;
  min-width: 150px;
  position: relative;
  width: 100%;
  select {
    border: unset;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
  }
  .input {
    align-items: center;
    background-color: var(--input-background-color);
    display: flex;
    font-size: 16px;
    height: 56px;
    justify-content: flex-start;
    padding: 0 20px;
    position: relative;
    width: 100%;
    .chevron {
      height: 13px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translatey(-50%);
    }
    .chevron::before {
      border-style: solid;
      border-width: 0.25em 0.25em 0 0;
      content: '';
      display: inline-block;
      height: 0.45em;
      left: 0.15em;
      position: relative;
      top: 0.15em;
      transform: rotate(-45deg);
      vertical-align: top;
      width: 0.45em;
    }
    .chevron.bottom::before {
      top: 0;
      transform: rotate(135deg);
    }
  }
  .dropdown {
    position: relative;
    .select-items {
      background-color: var(--primary-theme-color);
      border: 1px solid gray;
      opacity: 0;
      position: absolute;
      top: 5px;
      width: 100%;
      z-index: -1;
      .item {
        padding: 5px 20px;
        &.selected {
          background-color: var(--input-background-color);
        }
      }
    }
    &.expanded {
      .select-items {
        opacity: 1;
        z-index: 5;
      }
    }
    label {
      display: block;
    }
  }
  .error-message {
    font-size: 12px;
    height: 20px;
    visibility: hidden;
    &.visible {
      color: var(--error-message-color);
      visibility: visible;
    }
  }
}
