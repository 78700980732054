.fourth-banner {
  margin-bottom: 100px;
  .row {
    width: 100%;
    .buttons-section {
      display: flex;
      flex-wrap: wrap;
      .single-item {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 auto;
        max-width: 300px;
        padding: 20px;
        text-align: center;
      }
      button {
        margin-bottom: 20px;
        p {
          line-height: 17px;
          text-align: center;
        }
      }
    }
  }
}
