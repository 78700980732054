@import '../../../common/styles/variables.scss';

$checkbox-width: 19px;
$checkbox-height: 19px;
.checkbox {
  /* Customize the label (the container) */
  .chekbox-with-text {
    position: relative;
    .checkbox-label {
      display: block;
      padding-left: 40px;
    }
    .checkbox-wrapper {
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      height: $checkbox-height;
      left: 0;
      position: absolute;
      top: 4px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: $checkbox-width;
      /* Hide the browser's default checkbox */
      input {
        cursor: pointer;
        height: $checkbox-height;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: $checkbox-width;
        z-index: 1;;
      }

      /* Create a custom checkbox */
      .checkmark {
        background-color: var(--checkbox-not-checked-color);
        border: 1px solid var(--checkbox-checked-color);
        height: $checkbox-height;
        left: 0;
        position: absolute;
        top: 0;
        width: $checkbox-width;
      }

      /* On mouse-over, add a grey background color */
      &:hover input ~ .checkmark {
        background-color: var(--checkbox-not-checked-color);
      }
      /* When the checkbox is checked, add a blue background */
      input:checked ~ .checkmark {
        background-color: var(--checkbox-checked-color);
      }
    
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark::after {
        content: '';
        opacity: 0;
        position: absolute;
      }
    
      /* Show the checkmark when checked */
      input:checked ~ .checkmark::after {
        opacity: 1;
      }
      /* Style the checkmark/indicator */
      .checkmark::after {
        border: solid var(--checkbox-checkmark-color);
        border-width: 0 3px 3px 0;
        height: 8px;
        left: 5px;
        top: 2px;
        transform: rotate(45deg);
        width: 4px;
      }
    }
  }
  .error-message {
    font-size: 12px;
    height: 20px;
    visibility: hidden;
    &.visible {
      color: var(--error-message-color);
      visibility: visible;
  
    }
  }
}
  

  


  



  

