.first-banner {
  .mt-usual {
    margin-top: 20px;
  }
  .ml-usual {
    margin-left: 40px;
  }
  em {
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.4px;
    line-height: 28px;
  }
  .title {
    margin-bottom: 40px;
    text-align: center;
  }
  .underline {
    text-decoration: underline;
  }
  a {
    text-decoration: none;
  }
}
