
@import '../../../common/styles/variables';
.contact-us-container {
  padding-top: $header-height;
  .usual-banner {
    .content-items {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;
      padding-top: 100px;
      .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        .row {
          flex: 1;
        }
      }
     
   
    }
  }
  @import './first-banner';
}
